import {alias, define, init, inject, singleton} from '@injex/core';
import {Injex} from '@injex/webpack';
import {action, computed, makeObservable, observable, transaction} from 'mobx';
import IDisposable from '../../../common/interfaces/IDisposable';
import {SessionManager} from '../../session/managers/sessionManager.mdl';
import {RouterService} from '../../../common/router/services/routerService.mdl';
import {IUserNotification} from '../interfaces/IUserNotification';
import SocketManager from "../../../common/managers/SocketManager.mdl";
import Hook from '../../../common/utils/Hook';

export type UserNotificationsManagerHooks = {
    socketConnected: Hook
};

@define()
@singleton()
@alias('Disposable')
export class UserNotificationsManager implements IDisposable {
    @inject() private $injex: Injex;
    @inject() private sessionManager: SessionManager;
    @inject() public socketManager: SocketManager;

    public hooks: UserNotificationsManagerHooks;

    @observable.ref public items: IUserNotification[]
    @observable public itemsByType: { [type: string]: IUserNotification[] }
    @observable public isLoading: boolean
    @observable public loadError: boolean
    @observable public unseenCount: number
    @observable public selectedItemIndex: number
    @observable public selectedItem: IUserNotification
    @observable public search: string
    @observable public types: string[]
    @observable public userId: string

    constructor() {
        makeObservable(this);
        this.isLoading = false;
        this.loadError = false;
        this.items = observable.array([]);
        this.unseenCount = 0;
        this.selectedItemIndex = 0;
        this.selectedItem = null;
        this.itemsByType = {};
        this.types = [];
        this.search = "";
        this.userId = "";
        this.hooks = {
            socketConnected: new Hook()
        };
    }

    @init()
    protected initialize() {
        this.socketManager.userNotifications.hooks.socketConnected.tapAsync(this._initialize, null, this);
    }

    private async _initialize() {
        if (this.socketManager?.userNotifications?.isConnected) {
            await this.reloadData();
        }
    }

    private async reloadData() {
        await this.onStartListeningUserNotifications();
        await this.getItems(this.sessionManager.user._id);
    }

    @computed public get notifications(): IUserNotification[] {
        return this.items;
    }

    public async getItems(userId: string) {
        this.isLoading = true;

        this.socketManager.userNotifications.getNotificationsById(userId)
            .then(action((res: any) => this.onLoadItemsSuccess(res)))
            .catch(() => this.onLoadItemsError());
    }

    public onStartListeningUserNotifications() {
        this.socketManager.userNotifications.startListeningUserNotificationsCreated(this.onUpdateEventCreated);
        this.socketManager.userNotifications.startListeningUserNotificationsMarkAsSeen(this.onUpdateEventMarkAsSeen);
        this.socketManager.userNotifications.startListeningUserNotificationsUpdated(this.onUpdateEventUpdated);
    }

    public markAllAsRead(userId: string) {
        this.socketManager.userNotifications.markAllAsSeen(userId)
            .then(action(() => {
                this._markAll();
            }));
    }

    public updateUnseenNotificationsCount() {
        const unseen = this.items.filter((item) => item.seen === false);
        this.unseenCount = unseen.length;
    }

    protected onLoadItemsSuccess(data: any) {
        transaction(() => {
            this.isLoading = false;
            this.items = data.results;
            // this.setItemsByTypes(data.results);
            this.updateUnseenNotificationsCount();
        });
    }

    protected setItemsByTypes(results: any) {
        for (const item of results) {
            if (this.itemsByType[item.type] && this.itemsByType[item.type].length) {
                this.itemsByType[item.type].push(item);
            } else {
                this.types.push(item.type);
                this.itemsByType[item.type] = [item];
            }
        }
    }

    protected onLoadItemsError() {
        this.isLoading = false;
        this.loadError = true;
    }

    public onUpdateEventCreated = (data: any): any => {
        this.isLoading = true;
        const idx = this.items.findIndex((item) => item._id === data._id);
        if (idx < 0) {
            this.items.unshift(data);
            this.updateUnseenNotificationsCount();
        }
        this.isLoading = false;
    }

    public onUpdateEventMarkAsSeen = (data: any): any => {
        this.isLoading = true;
        const idx = this.items.findIndex((item) => item._id === data._id);
        this.markAsSeenReply(data, idx);
        this.isLoading = false;
    }

    public onSelectItem = async (selectedItemId) => {
        this.selectedItemIndex = this.items.findIndex((item) => item._id === selectedItemId);
        this.selectedItem = this.items[this.selectedItemIndex];

        if (!this.selectedItem.seen) {
            await this._markAsSeen();
        }
    }

    private markAsSeenReply(res, index) {
        if (this.selectedItem && (this.selectedItem._id === this.items[index]._id)) {
            this.selectedItem.seen = res.seen;
        }
        this.items[index].seen = res.seen;
        this.updateUnseenNotificationsCount();
    }

    private async _markAsSeen() {
        return this.socketManager.userNotifications.markAsSeen(this.selectedItem._id);
    }

    public setSearch(str: string) {
        this.selectedItemIndex = 0;
        this.selectedItem = null;
        this.search = str;
    }

    public clearSearch() {
        this.search = "";
    }

    @computed public get filteredItems() {
        if (this.items) {
            return this.items.filter((notification) => {
                    return (notification.subject && notification.subject.toLowerCase().indexOf(this.search.toLowerCase()) > -1) || (notification.message && notification.message.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
                }
            );
        }

        return null;
    }

    private onUpdateEventUpdated = (data: IUserNotification) => {
        this.isLoading = true;
        const index = this.items.findIndex((item) => item._id === data._id);
        if (this.selectedItem && (this.selectedItem._id === this.items[index]._id)) {
            this.selectedItem = data;
        }
        this.items[index] = data;
        this.updateUnseenNotificationsCount();
        this.isLoading = false;
    }

    private _markAll() {
        this.selectedItem && (this.selectedItem.seen = true);
        this.items.forEach((item) => item.seen = true);
        this.unseenCount = 0;
    }

    public dispose(): void {
        this.isLoading = false;
        this.loadError = false;
        this.items = observable.array([]);
        this.unseenCount = 0;
        this.selectedItemIndex = 0;
        this.selectedItem = null;
        this.itemsByType = {};
        this.types = [];
        this.search = ""
    }

    public _setUserId = () => {
        this.userId = this.sessionManager.user._id;
    };
}
