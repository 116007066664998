export enum OpenRtbNeededGroups {
	Connections = "connections",
	Exchanges = "exchanges"
}

export enum PlatformNeededGroups {
	Tags = "tags",
	Scenarios = "scenarios",
	Publishers = "publishers",
	Players = "players",
	DemandPartners = "demandPartners",
	Crawlers = "crawlers",
	Widgets = "widgets",
	WidgetsSettings = "widgetsSettings",
	Connections = "connections",
	Apps = "apps",
	Hosts = "hosts",
	Advertisers = "advertisers",
	CountryList = "countryList",
}
