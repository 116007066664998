import {ReportFieldValueType} from "../enums/ReportFieldValueType";
import {extractReportingFieldValue} from "./extractReportingFieldValue";
import dayjs from "dayjs";

const DATE_DIMENSIONS_FORMATS = {
    month: "MM/YYYY",
    day: "DD/MM/YYYY",
    hour: "DD/MM/YYYY HH:mm",
    time: "HH:mm",
    dayUSFormat: "MM/DD/YYYY",
};

export function getGroupValue(item: any, colKey: string) {
    let dateFormat = DATE_DIMENSIONS_FORMATS[colKey];
    (colKey === "Day US Format") && (dateFormat = DATE_DIMENSIONS_FORMATS.dayUSFormat)
    const group = item.groups.find((group) => group.key === colKey);
    if (dateFormat) {
        const ex = extractReportingFieldValue(group, "value")
        return dayjs(ex, dateFormat).unix()
    }

    return group?.value?.toLowerCase?.() ?? null;
}

export function getFieldValue(item: any, colKey: string) {
    return item.fields.find((field) => field.key === colKey)?.value ?? null;
}

export function searchInMetrics(item: any, colKey: string, query: string) {
    const field = item.fields.find((field) => field.key === colKey);
    query = query.replace(",", "");
    if (query.startsWith(">=")) {
        return field.value >= Number(query.replace(">=", ""));
    } else if (query.startsWith("<=")) {
        return field.value <= Number(query.replace("<=", ""));
    } else if (query.startsWith(">")) {
        return field.value > Number(query.replace(">", ""));
    } else if (query.startsWith("<")) {
        return field.value < Number(query.replace("<", ""));
    } else if (query.startsWith("!=")) {
        return field.value !== Number(query.replace("!=", ""));
    } else if (query.startsWith("=")) {
        return field.value === Number(query.replace("=", ""));
    } else {
        return field.value.toFixed() === Number(query).toFixed();
    }
}

export function searchInGroups(item: any, colKey: string, query: string) {
    const group = item.groups.find((group) => group.key === colKey);
    return group.value.toLowerCase().includes(query.toLowerCase());
}