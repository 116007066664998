import {useInjex} from '@injex/react-plugin';
import {RouterService} from '../services/routerService.mdl';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function useRouterService(): RouterService {
    const [inject] = useInjex();
    return inject<RouterService>('routerService');
}

export function useRouterNavigation() {
    const routerService = useRouterService();
    const navigate = useNavigate();

    useEffect(() => {
        routerService.setNavigate(navigate);
    }, [navigate, routerService]);

    return routerService;
}