import {define, init, inject, singleton} from '@injex/core';
import {SessionHooks, SessionManager} from '../../modules/session/managers/sessionManager.mdl';
import {IUserMetadata} from '../../modules/session/interfaces/IUserMetadata';
import {ISession, IUser} from '../../modules/session/interfaces/IAccountsSDK';
import {computed, makeObservable, observable} from 'mobx';
import ReportingEntries from '../../modules/reporting/common/models/ReportingEntries';
import {TimePresets} from '../enums/TimePresets';
import {ReportVerticalType} from '../enums/ReportVerticalType';
import IReportingEntry from '../../modules/reporting/common/interfaces/IReportingEntry';
import {compileFieldFormula} from '../utils/compileFieldFormula';
import {formatNumber, replaceLabelInFormula} from '../utils/utils';
import Hook from "../utils/Hook";
import theme from "../../modules/reporting/components/reportingHeader/theme.module.scss";
import LocalStorageManager from "./LocalStorageManager.mdl";

const NUMBER_FORMAT_REGEXP = /(.?)\.(0+)(.?)/;

export type ReportingMetaDataHooks = {
    timePresetChange: Hook;
};

@define()
@singleton()
export default class ReportingMetaDataManager {

    public hooks: ReportingMetaDataHooks;

    @inject() private sessionManager: SessionManager;
    @inject() private localStorageManager: LocalStorageManager;

    @observable public timePreset: TimePresets;
    @observable public isLoading: boolean;

    @observable public platformGroups: ReportingEntries;
    @observable public platformFields: ReportingEntries;
    @observable public platformFilters: ReportingEntries;
    @observable public platformCapsules: ReportingEntries;

    @observable public openRtbGroups: ReportingEntries;
    @observable public openRtbFields: ReportingEntries;
    @observable public openRtbFilters: ReportingEntries;
    @observable public openRtbCapsules: ReportingEntries;

    @observable public connectionGroups: ReportingEntries;
    @observable public connectionFields: ReportingEntries;
    @observable public connectionFilters: ReportingEntries;
    @observable public connectionCapsules: ReportingEntries;

    @init()
    protected initialize() {
        this.timePreset = TimePresets.TwoHours;
        this.isLoading = true;
        this.sessionManager.hooks.metadataLoad.tapAsync(this._onMetadataLoad, null, this);
        this.localStorageManager.hooks.onStorageReady.tap(this._onStorageReady, null, this);
    }

    constructor() {
        makeObservable(this);

        this.hooks = {
            timePresetChange: new Hook(),
        };
    }

    private _onStorageReady = () => {
        this.timePreset = this.localStorageManager.getTimePreset();
    }

    public onTimePresetChange = (timePreset: TimePresets) => {
        this.timePreset = timePreset;
        this.localStorageManager.setTimePreset(timePreset);
        this.hooks.timePresetChange.call();
    }

    private _onMetadataLoad(metadata: IUserMetadata, session: ISession) {
        this.platformGroups = new ReportingEntries(metadata.metaDataByVertical.platform.groups);
        this.platformFields = new ReportingEntries(metadata.metaDataByVertical.platform.fields.map((field) => this.enhanceField(field, metadata.metaDataByVertical.platform.fields)));
        this.platformFilters = new ReportingEntries(metadata.metaDataByVertical.platform.filters);
        this.platformCapsules = new ReportingEntries(metadata.metaDataByVertical.platform.capsules);

        this.openRtbGroups = new ReportingEntries(metadata.metaDataByVertical.open_rtb.groups);
        this.openRtbFields = new ReportingEntries(metadata.metaDataByVertical.open_rtb.fields);
        this.openRtbFilters = new ReportingEntries(metadata.metaDataByVertical.open_rtb.filters);
        this.openRtbCapsules = new ReportingEntries(metadata.metaDataByVertical.open_rtb.capsules);

        this.connectionGroups = new ReportingEntries(metadata.metaDataByVertical.connection.groups);
        this.connectionFields = new ReportingEntries(metadata.metaDataByVertical.connection.fields);
        this.connectionFilters = new ReportingEntries(metadata.metaDataByVertical.connection.filters);
        this.connectionCapsules = new ReportingEntries(metadata.metaDataByVertical.connection.capsules);

        this.isLoading = false;
    }

    @computed
    public get metaDataByVertical() {
        return {
            [ReportVerticalType.PLATFORM]: {
                groups: this.platformGroups,
                fields: this.platformFields,
                filters: this.platformFilters,
                capsules: this.platformCapsules,
            },
            [ReportVerticalType.OPEN_RTB]: {
                groups: this.openRtbGroups,
                fields: this.openRtbFields,
                filters: this.openRtbFilters,
                capsules: this.openRtbCapsules,
            },
            [ReportVerticalType.CONNECTION]: {
                groups: this.connectionGroups,
                fields: this.connectionFields,
                filters: this.connectionFilters,
                capsules: this.connectionCapsules,
            }
        }
    }

    public metricsAndCapsules(verticalType: ReportVerticalType) {
        const fields = this.metaDataByVertical[verticalType].fields.entries;
        const capsules = this.metaDataByVertical[verticalType].capsules.entries;
        return [
            {
                type: "header",
                label: "Metrics:",
                theme: {item: theme.metricsAndCapsules, label: theme.label}
            },
            ...fields,
            {
                type: "header",
                label: "Capsules:",
                theme: {item: theme.metricsAndCapsules, label: theme.label}
            },
            ...capsules
        ]
    }

    public getFieldByValue(value: string, vertical: ReportVerticalType): IReportingEntry {
        return this.metaDataByVertical[vertical].fields.getByValue(value);
    }

    public getFieldByLabel(label: string, vertical: ReportVerticalType): IReportingEntry {
        return this.metaDataByVertical[vertical].fields.getByLabel(label);
    }

    public getFieldById(id: string, vertical: ReportVerticalType): IReportingEntry {
        return this.metaDataByVertical[vertical].fields.getById(id);
    }

    public getGroupByValue(value: string, vertical: ReportVerticalType): IReportingEntry {
        return this.metaDataByVertical[vertical].groups.getByValue(value);
    }

    public getGroupByLabel(label: string, vertical: ReportVerticalType): IReportingEntry {
        return this.metaDataByVertical[vertical].groups.getByLabel(label);
    }

    public getFilterByValue(value: string, vertical: ReportVerticalType): IReportingEntry {
        return this.metaDataByVertical[vertical].filters.getByValue(value);
    }

    public getFilter(labelOrValue: string, vertical: ReportVerticalType): IReportingEntry {
        return this.metaDataByVertical[vertical].filters.get(labelOrValue);
    }

    public getCapsuleById(value: string, vertical: ReportVerticalType): IReportingEntry {
        return this.metaDataByVertical[vertical].capsules.getById(value);
    }

    protected enhanceField(field: IReportingEntry, fields: IReportingEntry[]): IReportingEntry {

        if (field.formula) {
            field.formulaDisplay = replaceLabelInFormula(field.formula as any, fields);
            field.formula = compileFieldFormula(field.formula);
        }

        if (field.format) {
            field.format = this.compileFieldFormat(field.format as any);
        }

        return field;
    }

    protected compileFieldFormat(format: string): (value: number) => string {
        const matches = format.match(NUMBER_FORMAT_REGEXP);
        if (matches) {
            const [pre, decimals, post] = matches.slice(1);
            return (value) => pre + formatNumber(value, decimals.length) + post;
        }

    }
}
