import {define, singleton} from '@injex/core';
import dayjs from 'dayjs';
import IDictionary from '../interfaces/IDictionary';
import * as papaito from "papaparse";
import IReportingEntry from "../../modules/reporting/common/interfaces/IReportingEntry";
import {IChangeLog} from "../../modules/change-logs/common/interfaces/IChangeLog";

@define()
@singleton()
export default class ExportToCsvManager {

    private getFileName(type: string): string {
        const date = dayjs().format("DD.MM.YY_HHmm");
        return `${type}_${date}.csv`;
    }

    private download(content: any, fileName: string) {
        const blob = new Blob([content]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    public exportDashboardReport(chartData: IDictionary<any>) {
        const head = chartData[0].map((data: { name: any; }) => data.name);
        const body = chartData.map((row: any[]) => row.map((data) => data.value));
        const csv = papaito.unparse({
            fields: head,
            data: body
        });
        this.download(csv, this.getFileName("vidazoo_dash_report"));
    }

    public exportHistoryReport(report: any[], params) {

        const csvArray = [[...params.groups, ...params.fields]];

        report.forEach((r) => {
            const rowValues = [];
            params.groups.forEach((groupLabel) => {
                rowValues.push(`"${r.groups[groupLabel]}"`);
            });
            params.fields.forEach((fieldLabel) => {
                rowValues.push(`"${r.fields[fieldLabel]}"`);
            });
            csvArray.push(rowValues);
        });

        const csv = csvArray.map((r) => (r.join())).join("\n");
        this.download(csv, this.getFileName("vidazoo_report_history"));
    }

    public exportReport(originalResults: Array<{
        fields: Array<{ name: string, value: any }>,
        groups: Array<{ name: string, value: number }>
    }>, fieldsParams: IReportingEntry[]) {

        const results = JSON.parse(JSON.stringify(originalResults))
        // filter needed fields
        results.forEach((row) => row.fields = row.fields.filter((field) => !!fieldsParams.find((curr) => curr.label === field.name)));

        const head = results[0].groups.map((g) => g.name).concat(results[0].fields.map((f) => f.name));
        const body = results.map((row) => {
            const groups = row.groups.map((g) => g.value);
            const fields = row.fields.map((f) => {
                if (Array.isArray(f.value)) {
                    const val = f.value;
                    return String([val[0], val[3].toFixed(2)]);
                }
                return String(f.value);
            });
            return [].concat(groups, fields);
        });

        const csv = papaito.unparse({
            fields: head,
            data: body,
        });

        this.download(csv, this.getFileName("vidazoo_report"));
    }

    public exportChangeLogsReport(data: IChangeLog[]) {
        const head = ["Date", "User", "Action", "Scope", "Diff"];

        const body = data.map((row) => {
            const user = `${row.auth.firstName || ''} ${row.auth.lastName || ''}`.trim() || row.auth.email || 'Unknown User';

            const diffString = JSON.stringify(row.diff);

            return [
                new Date(Number(row.date)).toLocaleString(),
                user,
                row.action,
                row.scope,
                diffString
            ];
        });

        const csv = papaito.unparse({
            fields: head,
            data: body
        });

        this.download(csv, this.getFileName("vidazoo_change_logs"));
    }

}
