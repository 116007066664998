import React, {useMemo} from 'react';
import {Navigate} from 'react-router-dom';
import {UserRole} from "../../enums/UserRole";
import useUserManager from "../../hooks/useUserManager";
import useSessionManager from "../../../modules/session/hooks/useSessionManager";
import UrlsService from "../../router/services/urlsService.mdl";
import {IndexRouteProps} from "react-router/dist/lib/components";

interface IAuthRouteProps extends IndexRouteProps {
    scope?: UserRole[];
    element: React.ReactElement;
}

export default function AuthRoute(props: IAuthRouteProps) {

    const {isInRoles} = useUserManager();
    const {isAuthenticated} = useSessionManager();

    const {
        scope = [],
        element: Element,
    } = props;

    if (!isAuthenticated) {
        return <Navigate to={UrlsService.app()} state={{from: location}} replace/>
    }

    const isValidScope = useMemo(() => scope.length ? isInRoles(scope) : true, [scope, isInRoles]);

    if (!isValidScope) {
        return <Navigate to={UrlsService.reporting()} replace/>;
    }

    return Element;
}
