import {define, singleton} from '@injex/core';

@define('sessionAPI')
@singleton()
export class SessionAPI {
    public async forgotPassword(email: string, recaptchaToken: string): Promise<void> {
        await window.fetch('https://accounts.vidazoo.com/api/password/forgot', {
            method: 'POST',
            body: JSON.stringify({email, token: recaptchaToken}),
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}