import {define, singleton} from '@injex/core';
import {NavigateFunction} from "react-router-dom";

@define()
@singleton()
export class RouterService {

    private navigate: NavigateFunction | null = null;

    public setNavigate(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    public push(path: string) {
        if (this.navigate) {
            this.navigate(path);
        } else {
            console.warn('Navigation function not set');
        }
    }
}
