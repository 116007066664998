import {EnvPlugin} from '@injex/env-plugin';
import {ReactPlugin} from '@injex/react-plugin';
import {Injex} from '@injex/webpack';
import dayjs from 'dayjs';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import {configure} from 'mobx';
import IEnv from './common/interfaces/IEnv';
import './common/styles/main.scss';
import reportWebVitals from './reportWebVitals';
import {LogLevel} from '@injex/core';

dayjs.extend(timezonePlugin);
dayjs.extend(utcPlugin);
dayjs.extend(customParseFormatPlugin);
dayjs.extend(relativeTime)

dayjs.Ls.en.weekStart = 1;

configure({
    enforceActions: 'never',
});

const container = Injex.create({
    resolveContext: () => require.context(__dirname, true, /\.mdl\.tsx?$/) as any,
    plugins: [
        new EnvPlugin<IEnv>({
            defaults: {
                recaptchaSiteKey: '6LdpvEAUAAAAAADPotdQeuDkMG5xp9LMrARwqRQ9',
                biDashboardsAPI: "https://bi-dashboards-api-2.vidazoo.com",
                // biDashboardsAPI: "http://localhost:9999",
                reporting: "https://reporting.vidazoo.com",
                reportingHistoryAPI: "https://reporting-history.vidazoo.com",
                // reportingHistoryAPI: "http://localhost:5848",
                userNotificationsAPI: "https://user-notifications.vidazoo.com",
            }
        }),
        new ReactPlugin({
            rootElementOrSelector: '#root',
        })
    ]
});

container.logger
    .setNamespace('BiDashboards')
    .setLogLevel(LogLevel.Info);

if (process.env.NODE_ENV === 'development') {
    container.logger.setLogLevel(LogLevel.Debug);
    (window as any).$injex = container;
}

container.bootstrap();

reportWebVitals(console.log);
