import {action, computed, observable, runInAction} from "mobx";
import {define, inject, singleton, init} from "@injex/core";
import {ReportVerticalType} from "../../../common/enums/ReportVerticalType";
import IDictionary from "../../../common/interfaces/IDictionary";
import ApiManager from "../../../common/api/ApiManager.mdl";
import {AlertsManager} from "../../../common/alerts/managers/alertsManager.mdl";
import {AlertAccent} from "../../../common/alerts/common/enums";
import AccountsMetaDataManager from "../../../common/managers/AccountsMetaDataManager.mdl";


@define()
@singleton()
export default class ReportingSubGroupsManager {

    @observable public subGroupsEntities: IDictionary<any>;

    @inject() private apiManager: ApiManager;
    @inject() private alertsManager: AlertsManager;
    @inject() private accountsMetaDataManager: AccountsMetaDataManager;

    constructor() {
        this.reset();
    }

    @init()
    protected initialize() {
        this.accountsMetaDataManager.hooks.accountsReady.tapAsync(this.init, null, this);
    }

    public init = async () => {
        await Promise.all(PlatformSubGroupsKeys.map(subGroup => this.getSubGroupsEntities(subGroup.value, ReportVerticalType.PLATFORM)));
    }

    public reset = () => {
        this.subGroupsEntities = {};
    };

    public async getSubGroupsEntities(group: string, vertical: ReportVerticalType) {
        if (!group) {
            return {isLoading: true, data: []};
        }
        if (this.subGroupsEntities[vertical + group]) {
            return this.subGroupsEntities[vertical + group];
        } else {
            this.subGroupsEntities[vertical + group] = {isLoading: true, data: []};

            await this._getSubGroupsEntities(group, vertical);
            return this.subGroupsEntities[vertical + group];
        }
    }

    private async _getSubGroupsEntities(group: string, vertical: ReportVerticalType) {
        try {
            const res = await this.apiManager.reporting.getSubGroupsEntities(vertical, group)
            this.subGroupsEntities[vertical + group] = {
                isLoading: false,
                data: res.data
            };
        } catch (e) {
            this.alertsManager.setBasicAlert({content: "Failed to get sub groups entities"}, AlertAccent.Error);
        }
    }
}

export const PlatformSubGroupsKeys = [
    {value: "widget", label: "Widget Id"},
    {value: "tagId", label: "Tag Id"},
    {value: "playerId", label: "Player Id"},
    {value: "scenarioId", label: "Scenario Id"},
    {value: "partnerTagId", label: "Demand Partner"},
    {value: "publisherId", label: "Publisher"},
    {value: "connectionId", label: "Connection"},

    {value: "domain", label: "Top Domain"},
    {value: "bundleId", label: "bundleId"},


];

export enum RequestedGroupValue {
    widget = "widgetOriginal",
    tagId = "tagName",
    playerId = "playerName",
    scenarioId = "scenarioName",
    partnerTagId = "partnerTagId",
    publisherId = "publisherName",
    connectionId = "connectionName",
    domain = "topDomain",
    bundleId = "domain"
}

export enum RequestedGroupLabel {
    widget = "Widget Original",
    tagId = "Tag",
    playerId = "Player",
    scenarioId = "Scenario",
    partnerTagId = "Demand Partner",
    publisherId = "Publisher",
    connectionId = "Connection",
    domain = "Top Domain"
}

export enum TargetingType {
    Domain = "domain",
    XRequestedWith = "x_requested_with",
    XRequestedWithList = "x_requested_with_list",
    DomainExact = "domain_exact",
    Platform = "platform",
    DomainList = "list",
    DomainListExact = "list_exact",
    Os = "os",
    Browser = "browser",
    ReferrerDomain = "referrerDomain",
    ReferrerDomainExact = "referrerDomain_exact",
    ReferrerList = "referrerList",
    ReferrerListExact = "referrerList_exact",
    Player = "player",
    Country = "country",
    CountryList = "countryList",
    Campaign = "campaign",
    CampaignList = "campaignList",
    Publisher = "publisher",
    AppDomain = "appDomain",
    AppName = "appName",
    AppNameExact = "appName_exact",
    AppNameList = "appNameList",
    AppNameListExact = "appNameList_exact",
    AppBundle = "appBundle",
    AppBundleList = "appBundleList",
    AppIosBundle = "appIosBundle",
    AppIosBundleList = "appIosBundleList",
    AppDomainList = "appDomainList",
    Param1 = "param1",
    Param2 = "param2",
    WidthRange = "width_range",
    HeightRange = "height_range",
    SizeClient = "size_client",
    DeviceIFA = "ifa",
    PartnerRank = "partner_rank",
    OsVersioned = "os_versioned",
    BrowserVersioned = "browser_versioned",
    ScenarioTagPrice = "scenario_tag_price",
    DynamicMacro = "dynamic_macro",
    WebView = "web_view",
    Network = "network",
    DetectedReferrerIncludes = "detected_referrer_includes",
    DetectedReferrerListIncludes = "detected_referrer_list_includes",
    DetectedReferrerExact = "detected_referrer_exact",
    DetectedReferrerListExact = "detected_referrer_list_exact",
    IsSecure = "is_secure",
    ViewabilityRate = "viewabilityRate",
    BrowserLanguage = "browser_language",
    SiteLanguage = "site_language",
    Url = "url",
    Account = "account",
    UrlList = "urlList",
    City = "city",
    Region = "region",
    Metro = "metro",
    Postal = "postal",
    PostalList = "postalList",
    RollPosition = "roll_position",
    PlayerVisible = "player_visible",
    ScrollHeight = "scrollHeight",
    PlayerStream = "playerStream",
    VideoTime = "video_time",
    AdNoStartTime = "ad_no_start_time",
    Viewability = "viewability",
    WidgetType = "widgetType",
    Widget = "widget",
    ActiveTime = "active_time",
    TimeOnSite = "time_on_site",
    AvgTimePercent = "avgTimePercent",
    MaxAdNoStartTime = "max_ad_no_start_time",
    MaxAdNoStartTimeOnSite = "max_ad_no_start_time_on_site",
    Tcf = "tcf",
    Source = "source",
    MaxTouchPoint = "maxTouchPoint",
    UrlParams = "urlParams",
    Pixelate = "pixelate",
    VideoPlacementTypeScenario = "videoPlacementTypeScenario",
    FillRate = "fillRate",
    FluidCpm = "fluidCpm",
    FluidFloorCpmExists = "fluidFloorCpmExists"
}

export const TargetingOperators = [
    {label: "IsAnyOf", value: "isAnyOf"},
    {label: "IsNotAnyOf", value: "isNotAnyOf"}
];

export const TargetingTypesSubGroups = [
    {label: "Domain", value: TargetingType.Domain},
    {label: "X-Requested-With", value: TargetingType.XRequestedWith},
    {label: "X-Requested-With List", value: TargetingType.XRequestedWithList},
    {label: "Domain Exact Match", value: TargetingType.DomainExact},
    {label: "Device", value: TargetingType.Platform},
    {label: "Domain List", value: TargetingType.DomainList},
    {label: "Domain List Exact Match", value: TargetingType.DomainListExact},
    {label: "OS", value: TargetingType.Os},
    {label: "OS Version", value: TargetingType.OsVersioned},
    {label: "Browser", value: TargetingType.Browser},
    {label: "Browser Version", value: TargetingType.BrowserVersioned},
    {label: "Referrer Domain", value: TargetingType.ReferrerDomain},
    {label: "Referrer Domain Exact Match", value: TargetingType.ReferrerDomainExact},
    {label: "Referrer Domain List", value: TargetingType.ReferrerList},
    {label: "Referrer Domain List Exact Match", value: TargetingType.ReferrerListExact},
    {label: "Player", value: TargetingType.Player},
    {label: "Campaign", value: TargetingType.Campaign},
    {label: "Campaign List", value: TargetingType.CampaignList},
    {label: "Country", value: TargetingType.Country},
    {label: "Country List", value: TargetingType.CountryList},
    {label: "Supply Partner", value: TargetingType.Publisher},
    {label: "App Domain", value: TargetingType.AppDomain},
    {label: "App Domain List", value: TargetingType.AppDomainList},
    {label: "App Bundle", value: TargetingType.AppBundle},
    {label: "App Bundle List", value: TargetingType.AppBundleList},
    {label: "App Name", value: TargetingType.AppName},
    {label: "App Name Exact Match", value: TargetingType.AppNameExact},
    {label: "App Name List", value: TargetingType.AppNameList},
    {label: "App Name List Exact Match", value: TargetingType.AppNameListExact},
    {label: "Demand Tag CPM", value: TargetingType.ScenarioTagPrice},
    {label: "Size Detected", value: TargetingType.SizeClient},
    {label: "Width Range", value: TargetingType.WidthRange},
    {label: "Height Range", value: TargetingType.HeightRange},
    {label: "Device IFA", value: TargetingType.DeviceIFA},
    {label: "Partner Rank", value: TargetingType.PartnerRank},
    {label: "Custom Param 1", value: TargetingType.Param1},
    {label: "Custom Param 2", value: TargetingType.Param2},
    {label: "Dynamic Macro", value: TargetingType.DynamicMacro},
    {label: "Web View", value: TargetingType.WebView},
    {label: "Network", value: TargetingType.Network},
    {label: "Detected Referrer Includes", value: TargetingType.DetectedReferrerIncludes},
    {label: "Detected Referrer Exact", value: TargetingType.DetectedReferrerExact},
    {label: "Detected Referrer List Includes", value: TargetingType.DetectedReferrerListIncludes},
    {label: "Detected Referrer List Exact", value: TargetingType.DetectedReferrerListExact},
    {label: "Secure Traffic (https)", value: TargetingType.IsSecure},
    {label: "Viewability Rate", value: TargetingType.ViewabilityRate},
    {label: "Viewability-Rate", value: TargetingType.ViewabilityRate},
    {label: "Browser Language", value: TargetingType.BrowserLanguage},
    {label: "Site Language", value: TargetingType.SiteLanguage},
    {label: "Url", value: TargetingType.Url},
    {label: "Account", value: TargetingType.Account},
    {label: "Url List", value: TargetingType.UrlList},
    {label: "City", value: TargetingType.City},
    {label: "Region", value: TargetingType.Region},
    {label: "Metro Code (DMA)", value: TargetingType.Metro},
    {label: "Postal Code", value: TargetingType.Postal},
    {label: "Postal Code List", value: TargetingType.PostalList},
    {label: "Roll Position", value: TargetingType.RollPosition},
    {label: "Player Visible", value: TargetingType.PlayerVisible},
    {label: "Player Stream", value: TargetingType.PlayerStream},
    {label: "Video Content Time", value: TargetingType.VideoTime},
    {label: "Time gap between ad impressions", value: TargetingType.AdNoStartTime},
    {label: "Real time Viewability", value: TargetingType.Viewability},
    {label: "Widget Type", value: TargetingType.WidgetType},
    {label: "Widget", value: TargetingType.Widget},
    {label: "Real Time Targeting", value: TargetingType.ActiveTime},
    {label: "Average Historic Time on Site", value: TargetingType.TimeOnSite},
    {label: "Average Time Percent", value: TargetingType.AvgTimePercent},
    {label: "Max time between ad impressions", value: TargetingType.MaxAdNoStartTime},
    {label: "Max time between ad impressions by average time", value: TargetingType.MaxAdNoStartTimeOnSite},
    {label: "TCF String Defined", value: TargetingType.Tcf},
    {label: "Url Params", value: TargetingType.UrlParams},
    {label: "Video Placement Type", value: TargetingType.VideoPlacementTypeScenario},
    {label: "Fill Rate", value: TargetingType.FillRate},
    {label: "Fluid Cpm", value: TargetingType.FluidCpm},
    {label: "Fluid Floor Cpm Exists", value: TargetingType.FluidFloorCpmExists},
    {label: "Max Touch Point", value: TargetingType.MaxTouchPoint},
    {label: "Source", value: TargetingType.Source},
];
