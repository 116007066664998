import {define, init, inject, singleton} from '@injex/core';
import {intercept, makeObservable, observable, toJS} from 'mobx';
import IReportingEntry from '../common/interfaces/IReportingEntry';
import IReportingFilter from '../common/interfaces/IReportingFilter';
import IReportingConstraint from '../common/interfaces/IReportingConstraint';
import dayjs from 'dayjs';
import LocalStorageManager from '../../../common/managers/LocalStorageManager.mdl';
import {ReportingContextMenuType, ReportingContextMenuTypeValues} from '../common/enums/ReportingContextMenuType';
import {IBiReportingParams} from '../common/interfaces/IReportingParams';
import {CUSTOM_COMPARE_DATE_KEYS} from '../common/constants/constants';
import IReportingPreset from '../common/interfaces/IReportingPreset';
import {
    dayjsToTimeZoneUnix,
    getCurrentHour,
    getCurrentMonth,
    getCurrentWeek,
    getLastMonth,
    getPastDays
} from '@vidazoo/date-range';
import {guid, OppositeTimezone} from '@vidazoo/ui-kit';
import ReportingMetaDataManager from '../../../common/managers/ReportingMetaDataManager.mdl';
import ReportingFiltersManager from '../../../common/reporting-filters/managers/ReportingFiltersManager.mdl';
import {ReportVerticalType} from '../../../common/enums/ReportVerticalType';
import {TimePreset, TimePresets} from '@vidazoo/date-range';
import IChartReportingParams from '../../charts/interfaces/IChartReportingParams';
import {ISubGroup} from "../common/interfaces/ISubGroup";
import {IReportHistory} from "../common/interfaces/IReportHistory";
import {uniqueBy} from "../../../common/utils/utils";
import {AlertsManager} from "../../../common/alerts/managers/alertsManager.mdl";
import {ValidTimeZones} from "../../../common/enums/ValidTimeZones";
import {ConstraintsOperators} from "../common/enums/ConstraintsOperators";
import {extractReportingFieldValue} from "../common/utils/extractReportingFieldValue";
import {AlertAccent} from "../../../common/alerts/common/enums";
import {VALID_PERIODS} from "../common/enums/periods";
import URLQueryParams from "../../../common/utils/URLQueryParams";
import ReportingSubGroupsManager, {RequestedGroupLabel, RequestedGroupValue} from "./reportingSubGroupsManager.mdl";
import {IReportingPreparedGroup} from "../common/interfaces/IReportingPreparedResults";

@define()
@singleton()
export default class ReportingParamsManager {

    @inject() private reportingFiltersManager: ReportingFiltersManager;
    @inject() private localStorageManager: LocalStorageManager;
    @inject() private reportingMetaDataManager: ReportingMetaDataManager;
    @inject() private alertsManager: AlertsManager;
    @inject() private reportingSubGroupsManager: ReportingSubGroupsManager;

    @observable public historyParams: IBiReportingParams[];
    @observable public params: IBiReportingParams;
    @observable public includeOriginals: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @init()
    private initialize() {
        this.connectHooks();
        this.initialParams();
    }

    private initialParams() {
        const localStorageParams = this.localStorageManager.getReportingParams();

        this.params = observable.object({
            time: TimePreset.Now,
            from: dayjs(),
            to: dayjs(),
            isCompare: localStorageParams.isCompare || false,
            compareTo: dayjs(),
            compareFrom: dayjs(),
            period: localStorageParams.period || "previous_period",
            fields: localStorageParams.fields || [],
            groups: localStorageParams.groups || [],
            filters: localStorageParams.filters || [],
            constraints: localStorageParams.constraints || [],
            timezone: localStorageParams.timezone || "Etc/GMT+0",
            verticalType: ReportVerticalType.PLATFORM,
            subGroups: localStorageParams.subGroups || [],
            capsules: localStorageParams.capsules || [],
        });
        intercept(this.params, this.paramsChange);
    }

    public connectHooks() {
        this.localStorageManager.hooks.onStorageReady.tap(this.initialParams.bind(this));
    }

    private cleanParams() {
        const localStorageParams = this.localStorageManager.getReportingParams();

        this.params = {
            capsules: [],
            subGroups: [],
            time: TimePreset.Now,
            from: dayjs(),
            to: dayjs(),
            isCompare: false,
            compareTo: null,
            compareFrom: null,
            period: "previous_period",
            fields: [],
            groups: [],
            filters: [],
            constraints: [],
            verticalType: ReportVerticalType.PLATFORM,
            timezone: localStorageParams.timezone || "Etc/GMT+0",
            dbType: null
        };
    }

    public addFilter = (): IReportingFilter => {
        const filter = observable<IReportingFilter>({
            id: guid(),
            key: "",
            values: [],
            filterList: [],
            isLoading: false,
            filterValueKey: "",
            filterLabelKey: "",
            allowNew: false,
            exclude: false,
            operator: "",
        });

        this.params.filters = this.params.filters.concat([filter]);

        return filter;
    };

    public addSubDimension = () => {
        const subDimension = {
            group: "",
            entities: [],
            id: guid(),
            targeting: {
                types: [],
                operators: []
            }
        };
        this.params.subGroups.push(subDimension);

        return subDimension;
    };

    public setStorageParams = () => {
        this.localStorageManager.setReportingParams(this.params);
    }

    public get preparedParams(): IBiReportingParams {
        const dto: any = {
            from: dayjsToTimeZoneUnix(this.params.from, OppositeTimezone[this.params.timezone]),
            to: dayjsToTimeZoneUnix(this.params.to, OppositeTimezone[this.params.timezone]),
            fields: this.prepareReportFieldsByVertical(this.params.fields, this.params.verticalType),
            groups: this.params.groups.map((group) => group.value),
            subGroups: this.params.subGroups,
            itemType: "player",
            filters: this.prepareReportFilters(this.params.filters),
            constraints: this.prepareReportConstraints(this.params.constraints),
            dbType: this.params.dbType,
            timeZone: this.params.timezone,
            accountsId: this.params.accountsId,
            origin: "bi_dashboards",
            verticalType: this.params.verticalType,
            includeOriginals: true
        };

        if (this.params.sample && this.params.sample > 0) {
            dto.sample = this.params.sample;
        }

        if (this.params.isCompare) {
            dto.compareFrom = dayjsToTimeZoneUnix(this.params.compareFrom, this.params.timezone);
            dto.compareTo = dayjsToTimeZoneUnix(this.params.compareTo, this.params.timezone);
        }

        return dto;
    }

    public get prepareOriginFields(): string[] {
        return this.params.fields.map((field) => {
            if (field.type === "capsule") {
                return (field as any)._id;
            }
            return field.value;
        });
    }

    protected prepareReportFieldsByVertical(fields: IReportingEntry[], vertical: ReportVerticalType) {
        let neededFields = [];
        fields.filter((currFilter) => currFilter.type !== "formula").forEach((field) => {
            if (field.type === "capsule" && field["fields"]) {
                const capsulesFields = [];
                field["fields"].forEach((fieldId: string) => {
                    const capsuleField = this.reportingMetaDataManager.getFieldById(fieldId, vertical);
                    capsuleField && capsulesFields.push(capsuleField.value);
                });
                neededFields = neededFields.concat(capsulesFields);
            } else {
                neededFields.push(field.value);
            }
        });

        return uniqueBy(neededFields);
    }

    protected prepareReportFilters(filters: IReportingFilter[]) {
        if (!filters || !filters.length) {
            return [];
        }

        return filters.map((filter: IReportingFilter) => {

            const filterHandler = this.reportingFiltersManager.getFilter(filter.key, ReportVerticalType.PLATFORM);
            if (!filterHandler) {
                return null;
            }
            return filterHandler.reportify(filter);

        }).filter(Boolean);
    }

    protected prepareReportConstraints(constraints: IReportingConstraint[]) {
        if (!constraints || !constraints.length) {
            return [];
        }

        return constraints
            .map((constraint: IReportingConstraint) => {
                if (constraint.name && constraint.op && constraint.value) {
                    return {
                        name: constraint.name,
                        op: constraint.op,
                        value: constraint.value
                    };
                }
                return null;
            })
            .filter(Boolean);
    }

    public onClearAllParams = (key: ReportingContextMenuType) => {
        if (ReportingContextMenuTypeValues.includes(key)) {
            this.params[key] = [];
        }
    };

    public onMultiplePaste = async (param: string, filter?: IReportingFilter) => {
        try {

            // while working on local ENV please change to localhost instead of vidazoo.local.com (devServer.js)
            const content = await navigator.clipboard.readText();
            if (!content) {
                this.alertsManager.setBasicAlert({content: "No Clipboard Data, Your clipboard is empty."}, AlertAccent.Error);
                return;
            }


            let items = this.splitForPaste(content);

            param === "filters" ? (items = this.prepareForPasteByFilter(items, filter)) : (items = this.prepareForPaste(param, items));

            if (items.length === 0) {
                this.alertsManager.setBasicAlert({content: "Oops! Paste data does not match."}, AlertAccent.Error);
            }

            param === "filters" ? (filter.values = filter.values.concat(items)) : (this.params[param] = this.params[param].concat(items));

        } catch (e) {
            this.alertsManager.setBasicAlert({content: "No Clipboard permission, Please check browser clipboard permission."}, AlertAccent.Error);
        }
    }

    private splitForPaste(clipboardData: string): string[] {

        let splitBy;

        if (clipboardData.includes(",")) {
            splitBy = ",";

        } else if (clipboardData.includes("\n")) {
            splitBy = /[\r\n]+/;

        } else if (clipboardData.includes("\t")) {
            splitBy = "\t";
        }
        if (splitBy) {
            return clipboardData.split(splitBy).map((item) => item.trim());
        }

        return [clipboardData];
    }

    private prepareForPasteByFilter(items: string[], filter: IReportingFilter) {
        if (filter.filterList.length > 0 && filter.filterValueKey && filter.filterLabelKey) {
            const newItems = [];
            const currentValuesLabels = filter.values.map((value: any) => value[filter.filterLabelKey].toLowerCase());
            items.forEach((item) => {
                item = item.trim().toLowerCase();
                for (const allowedFilter of filter.filterList) {
                    if (allowedFilter[filter.filterLabelKey]?.toLowerCase() === item && !currentValuesLabels.includes(item)) {
                        newItems.push({
                            [filter.filterLabelKey]: allowedFilter[filter.filterLabelKey],
                            [filter.filterValueKey]: allowedFilter[filter.filterValueKey]
                        });
                    }
                }
            });
            return newItems;
        }
        return items;
    }

    private prepareForPaste(param: string, items: string[]) {
        const newItems = [];
        items.forEach((item) => {
            const metaData = this.reportingMetaDataManager.metaDataByVertical[this.params.verticalType][param];
            const entry = metaData.get(item);
            if (entry) {
                const isAlreadyExist = this.params[param].find((field) => field.value === entry.value);
                !isAlreadyExist && newItems.push(entry);
            }
        });
        return newItems;
    }

    public setFilterKey = async (filter: IReportingFilter, key: string): Promise<any> => {
        const filterHandler = this.reportingFiltersManager.getFilter(key, ReportVerticalType.PLATFORM);

        filter.key = key;
        filter.filterLabelKey = filterHandler.labelKey;
        filter.filterValueKey = filterHandler.valueKey;
        filter.allowNew = filterHandler.allowNew;
        filter.isLoading = filterHandler.isLoading;
        filter.values = [];

        return filterHandler.initialize().then(() => {
            filter.filterList = observable.array(filterHandler.items, {deep: false});
            filter.isLoading = filterHandler.isLoading;
        });
    }

    public setFilterParam = (filter: IReportingFilter, key: string, value: any) => {
        if (key === "key") {
            this.setFilterKey(filter, value);
            return;
        }
        filter[key] = value;
    };

    public removeFilter = (filter: IReportingFilter) => {
        this.params.filters = this.params.filters.filter((f) => f.id !== filter.id);
    };

    public pushFilterValue = (filter: IReportingFilter, value: string, label: string) => {
        let item: any = value;

        if (filter.filterLabelKey && filter.filterValueKey) {
            item = {
                [filter.filterLabelKey]: label,
                [filter.filterValueKey]: value
            };
        }
        filter.values = filter.values.concat(item);
    };

    public removeFilterValue = (filter: IReportingFilter, value: string) => {
        filter.values = filter.filterValueKey
            ? filter.values.filter((item) => item[filter.filterValueKey] !== value)
            : filter.values.filter((item) => item !== value);
    };

    public removeReportParam = (key: string, value: string) => {
        this.params[key] = this.params[key].filter((param) => param.value !== value);
    };

    public setParam = (key: keyof IBiReportingParams, value: any) => {

        if (CUSTOM_COMPARE_DATE_KEYS.indexOf(key) > -1) {
            this.applyComparePeriod(TimePreset.Custom);
        }

        this.params[key as any] = value;

        if (key === "timezone" && this.params.time === "now") {
            this.applyTimePreset();
        }

        if (key === "isCompare" && this.params.isCompare) {
            this.applyComparePeriod();
        }
    };

    public pushReportParam = (key: string, value: string, label: string) => {
        this.params[key] = this.params[key].concat([{value, label}]);
    };

    public setPreset = (preset: TimePreset) => {
        this.params.time = preset;
    }

    public addConstraint = (values?: Partial<IReportingConstraint>) => {
        this.params.constraints = this.params.constraints.concat([{
            id: guid(),
            name: "",
            op: "",
            value: "",
            ...values
        }]);

        return this.params.constraints[this.params.constraints.length - 1];
    };

    public setConstraintParam = (constraint: IReportingConstraint, key: string, value: any) => {
        constraint[key] = value;
    };

    public removeConstraint = (constraint: IReportingConstraint) => {
        this.params.constraints = this.params.constraints.filter((c) => c.id !== constraint.id);
    };

    public rollBackParams = () => {
        if (this.historyParams && this.historyParams.length > 0) {
            const lastParams = this.historyParams[this.historyParams.length - 1];
            this.params = observable(lastParams);
            intercept(this.params, this.paramsChange);
            this.historyParams.pop();
        }
    };

    private paramsChange = (change: any) => {
        const clonedParams = toJS(this.params);
        if (!this.historyParams) {
            this.historyParams = [];
        }
        this.historyParams.push(clonedParams);
        if (this.historyParams.length > 100) {
            this.historyParams.shift();
        }
        return change;
    };

    public applyTimePreset = (preset?: TimePreset) => {
        if (preset && preset !== this.params.time) {
            this.params.time = preset;
        }

        switch (this.params.time) {
            case TimePreset.Now:
                this.setDate(1, 1, true);
                break;
            case TimePreset.Today:
                this.setDate(getCurrentHour(), -getCurrentHour() + 24);
                break;
            case TimePreset.Yesterday:
                this.setDate(getCurrentHour() + 24, -getCurrentHour());
                break;
            case TimePreset.Last7Days:
                this.setDate(getPastDays(7), -getCurrentHour() + 24);
                break;
            case TimePreset.WeekToDate:
                this.setDate(getCurrentWeek(), -getCurrentHour() + 24);
                break;
            case TimePreset.LastMonth:
                this.setDate(getLastMonth(), -getCurrentMonth());
                break;
            case TimePreset.MonthToDate:
                this.setDate(getCurrentMonth(), -getCurrentHour() + 24);
                break;
        }

        if (this.params.isCompare) {
            this.applyComparePeriod();
        }
    };

    public setDate(from: number, to: number, withTimezone?: boolean) {
        if (withTimezone) {
            const offset = this.getTimezoneOffset() / 60;

            from += offset;
            to -= offset;

        }
        const dayjsFrom = dayjs().utc().minute(0).second(0).subtract(from, "hour");
        const dayjsTo = dayjs().utc().minute(0).second(0).add(to, "hour");

        this.params.from = dayjsFrom
        this.params.to = dayjsTo
    }

    private getTimezoneOffset(): number {
        return dayjs.tz(dayjs.utc().minute(0).second(0), OppositeTimezone[this.params.timezone]).utcOffset();
    }

    public applyComparePeriod = (period?: TimePreset) => {
        if (period && period !== this.params.period) {
            this.setParam("period", period);
        }

        if (this.params.period === "custom") {
            return;
        }

        this.params.compareFrom = this.params.from.clone();
        this.params.compareTo = this.params.to.clone();

        const numberOfDiffDays = Math.max(1, this.params.compareTo.diff(this.params.compareFrom, "days"));

        switch (this.params.period) {
            case "previous_period":
                this.params.compareFrom.subtract(numberOfDiffDays, "days");
                this.params.compareTo.subtract(numberOfDiffDays, "days");
                break;
            case "previous_month":
                this.params.compareFrom.subtract(1, "month");
                this.params.compareTo.subtract(1, "month");
                break;
            case "previous_year":
                this.params.compareFrom.subtract(1, "year");
                this.params.compareTo.subtract(1, "year");
                break;
        }
    };

    public resetParams = () => {
        this.cleanParams();
        intercept(this.params, this.paramsChange);
        this.applyTimePreset(this.params.time);
    };

    public applyReportPreset = async (preset: IReportingPreset) => {

        this.params.constraints = [];

        this.params.verticalType = preset.vertical;

        for (let i = 0, len = preset.constraints.length; i < len; i++) {
            const constraint = preset.constraints[i];

            if (constraint && constraint.name) {
                const field = this.reportingMetaDataManager.getFieldByValue(constraint.name.value || constraint.name, preset.vertical);

                if (field) {
                    this.addConstraint({
                        name: field.value,
                        op: constraint.op,
                        value: constraint.value
                    });
                }
            }
        }

        this.params.filters = [];

        for (let i = 0, len = preset.filters.length; i < len; i++) {
            const filter = preset.filters[i];

            const entry = this.reportingMetaDataManager.metaDataByVertical[preset.vertical].filters.get(filter.key);

            const newFilter = this.addFilter();

            this.setFilterParam(newFilter, "operator", filter.operator);

            await this.setFilterKey(newFilter, entry.value);

            const stringList = newFilter.allowNew;

            filter.values.forEach((item: { [x: string]: any; name: any; }) => {
                const label = item[newFilter.filterLabelKey] || item.name || item;
                const filterValue = !stringList ? this.reportingFiltersManager.getFilterListValueByLabel(newFilter, label, preset.vertical) : typeof (item) === "string" ? item : null;

                if (filterValue) {
                    this.pushFilterValue(newFilter, filterValue, label);
                } else {
                    const filterListLabelByValue = this.reportingFiltersManager.getFilterListLabelByValue(newFilter, label, preset.vertical);
                    if (filterListLabelByValue) {
                        this.pushFilterValue(newFilter, label, filterListLabelByValue);
                    }
                }
            });
        }

        this.setParam("fields", []);

        preset.fields.forEach((value) => {
            const entry = this.reportingMetaDataManager.metaDataByVertical[preset.vertical].fields.get(value);

            if (entry) {
                this.pushReportParam("fields", entry.value, entry.label);
            }
        });

        this.setParam("groups", []);

        preset.groups.forEach((value) => {
            const entry = this.reportingMetaDataManager.metaDataByVertical[preset.vertical].groups.get(value);

            if (entry) {
                this.pushReportParam("groups", entry.value, entry.label);
            }
        });

        if (preset.subGroups) {
            for (let i = 0, len = preset.subGroups.length; i < len; i++) {
                const subGroup = preset.subGroups[i];
                await this.reportingSubGroupsManager.getSubGroupsEntities(subGroup.group, preset.vertical);
            }

            this.params.subGroups = preset.subGroups;
        }

        this.setParam("timezone", preset.timezone);

        const isValidTimePreset = TimePresets.map(p => p.value).includes(preset.timespan);

        if (isValidTimePreset) {
            this.applyTimePreset(preset.timespan);
        }
    };

    public getReportParamsForChart(params: IChartReportingParams): any {
        return {
            fields: params.fields,
            groups: params.groups,
            filters: this.prepareReportFilters(params.filters),
            constraints: this.prepareReportConstraints(params.constraints),
            origin: "bi_dashboards",
        };
    }

    public async setParamsFromHistoryReport(item: IReportHistory) {
        this.cleanParams()
        const fields = (item.originFields && item.originFields.length > 0) ? item.originFields : item.params.fields;
        await Promise.all([
            this.setReportVerticalTypeFromFlatten(item.params.verticalType),
            this.setReportFiltersFromFlatten(item.params.filters),
            this.setReportGroupsFromFlatten(item.params.groups),
            this.setReportSubGroupsFromReportHistory(item.params.subGroups),
            this.setReportFieldsFromFlatten(fields),
            this.setReportConstraintsFromFlatten(item.params.constraints),
            this.setReportTimeZoneFromFlatten(item.params.timeZone),
            this.setReportTimeFrameFromReportHistory(item.params.from, item.params.to),
            this.setReportCompareTimeFrameFromReportHistory(item.params.compareFrom, item.params.compareTo),
        ]);
        this.extractParamsFields(true);
    }

    private setReportVerticalTypeFromFlatten(verticalType: ReportVerticalType): boolean {
        let isSet = false;

        if (verticalType) {
            isSet = true;
            this.params.verticalType = verticalType;
        }

        return isSet;
    }

    private async setReportFiltersFromFlatten(filters: any[]): Promise<boolean> {
        let isSet = false;

        if (filters && filters.length > 0) {
            isSet = (await Promise.all(filters.map(async (filter) => {

                let isSetFilter = false;
                if (filter.key && filter.values && filter.values.length > 0) {
                    const entry = this.reportingMetaDataManager.getFilter(filter.key, this.params.verticalType);

                    if (entry) {
                        const newFilter = this.addFilter();

                        this.setFilterParam(newFilter, "operator", filter.operator);

                        await this.setFilterKey(newFilter, entry.value);

                        const stringList = newFilter.allowNew;

                        filter.values.forEach((label) => {
                            const filterValue = !stringList
                                ? this.reportingFiltersManager.getFilterListValueByLabel(newFilter, label, this.params.verticalType)
                                : typeof (label) === "string"
                                    ? label
                                    : null;

                            if (filterValue) {
                                isSetFilter = true;

                                this.pushFilterValue(newFilter, filterValue, label);
                            } else {
                                const filterListLabelByValue = this.reportingFiltersManager.getFilterListLabelByValue(newFilter, label, this.params.verticalType);
                                if (filterListLabelByValue) {
                                    this.pushFilterValue(newFilter, label, filterListLabelByValue);
                                }
                            }
                        });
                    }
                }

                return isSetFilter;
            }))).some((item) => item === true);
        }

        return isSet;
    }

    private setReportGroupsFromFlatten = (groups: any[]): boolean => {
        let isSet = false;

        if (groups && groups.length > 0) {
            for (let label of groups) {

                let group = this.reportingMetaDataManager.getGroupByLabel(label, this.params.verticalType);
                if (!group) {
                    group = this.reportingMetaDataManager.getGroupByValue(label, this.params.verticalType);
                }

                if (group) {
                    isSet = true;
                    this.pushReportParam("groups", group.value, group.label);
                }
            }
        }

        return isSet;
    }

    private setReportSubGroupsFromReportHistory(subGroups: ISubGroup[]): boolean {
        this.params.subGroups = subGroups;
        return true;
    }

    private setReportFieldsFromFlatten(fields: any[]): boolean {
        let isSet = false;

        if (fields && fields.length > 0) {
            fields.forEach((label) => {
                let field = this.reportingMetaDataManager.getFieldByLabel(label, this.params.verticalType);
                if (!field) {
                    field = this.reportingMetaDataManager.getFieldByValue(label, this.params.verticalType);
                }

                if (!field) {
                    field = this.reportingMetaDataManager.getCapsuleById(label, this.params.verticalType);
                }

                if (field) {
                    isSet = true;
                    this.params.fields = this.params.fields.concat(field);
                }
            });
        }
        return isSet;
    }

    private setReportConstraintsFromFlatten(constraints: any[]): boolean {
        let isSet = false;

        if (constraints && constraints.length > 0) {
            constraints.forEach((constraint) => {

                if (constraint.name && constraint.op && ConstraintsOperators.indexOf(constraint.op) > -1 && !isNaN(constraint.value)) {

                    const entry = this.reportingMetaDataManager.getFieldByLabel(constraint.name, this.params.verticalType);

                    if (entry) {
                        isSet = true;

                        this.addConstraint({
                            name: entry.value,
                            op: constraint.op,
                            value: constraint.value
                        });
                    }
                }
            });
        }

        return isSet;
    }

    private setReportTimeZoneFromFlatten(timezone: string): boolean {
        let isSet = false;

        if (timezone && ValidTimeZones.indexOf(timezone) > -1) {
            isSet = true;
            this.params.timezone = timezone;
        }

        return isSet;
    }

    private setReportTimeFrameFromReportHistory(from: number, to: number): boolean {
        let isSet = false;

        if (from && to) {
            const fromMoment = dayjs(Number(from) * 1000).utc(false);
            const toMoment = dayjs(Number(to) * 1000).utc(false);

            if (fromMoment.isValid() && toMoment.isValid() && fromMoment < toMoment) {
                this.setParam("from", fromMoment);
                this.setParam("to", toMoment);
                isSet = true;
            }
        }

        return isSet;
    }

    private setReportCompareTimeFrameFromReportHistory(from: number, to: number): boolean {
        let isSet = false;

        if (from && to) {
            const fromMoment = dayjs(from * 1000).utc(false);
            const toMoment = dayjs(to * 1000).utc(false);
            this.setParam("isCompare", true);

            if (fromMoment.isValid() && toMoment.isValid() && fromMoment < toMoment) {
                this.setParam("compareFrom", fromMoment);
                this.setParam("compareTo", toMoment);
                isSet = true;
            }
        }

        return isSet;
    }

    public get extractedParamsFields() {
        return this.extractParamsFields();
    }

    private extractParamsFields(clearDuplicated?: boolean) {
        let allFields = [];
        let capsulesFields = [];
        const basicFields = [];
        this.params.fields.forEach((entry) => {
            if (entry.type === "capsule" && entry["fields"]) {
                const capsuleFields = [];
                entry["fields"].forEach((fieldId: string) => {
                    const field = this.reportingMetaDataManager.getFieldById(fieldId, this.params.verticalType);
                    field && capsuleFields.push(field);
                });
                allFields = allFields.concat(capsuleFields);
                capsulesFields = capsulesFields.concat(capsuleFields.map((field) => field.label));
            } else {
                allFields.push(entry);
                basicFields.push(entry.label);
            }
        });
        if (clearDuplicated) {
            this._handleClearFieldsDuplicated(capsulesFields, basicFields);
        }

        return uniqueBy(allFields, "value");
    }

    private _handleClearFieldsDuplicated(capsulesFields: string[], basicFields: string[]) {
        let removed = "";
        basicFields.forEach((field) => {
            if (capsulesFields.includes(field)) {
                removed += `${field}, `;
                this.removeReportParam("fields", this.reportingMetaDataManager.getFieldByLabel(field, this.params.verticalType).value);
            }
        });

        if (removed) {
            this.alertsManager.setBasicAlert({
                content: `Metrics removed ${removed} already exists in one of the capsules selected`,
                ttl: 10000
            });
        }
    }

    public quickFilter = (item: IReportingPreparedGroup) => {
        const {originalId, name} = item;
        const dimension = this.reportingMetaDataManager.getGroupByLabel(name === "Browser Name" ? "Browser" : name, this.params.verticalType);
        let filter = this.params.filters.find((currFilter) => currFilter.key === dimension.value);
        if (!filter) {
            filter = this.addFilter();
            if (name === "Browser Name") {
                this.setFilterParam(filter, "key", "browser");
                this.setFilterParam(filter, "operator", "containAnyOf");
            } else {
                this.setFilterParam(filter, "key", dimension.value);
                this.setFilterParam(filter, "operator", dimension.operators[0]);
            }
        }
        let value = originalId || item.value;
        switch (name) {
            case "Top Domain":
            case "Tag Partner Name":
            case "SSP":
                value = item.value;
                break;
            case "Widget":
                value = item.valueDisplay;
                break;
            case "Publisher Account":
                value = item.originalValue;
                break;
        }
        if (filter.values.some((val) => val === value || val[filter.filterValueKey] === value)) {
            return;
        }

        this.pushFilterValue(filter, value, item.value);
    };

    public quickConstraint = (item) => {
        this.addConstraint({
            name: this.reportingMetaDataManager.getFieldByLabel(item.name, this.params.verticalType).value,
            op: ">=",
            value: extractReportingFieldValue(item, "value")
        });
    };

    public async setInitialParams(query: string) {

        if (query) {
            try {
                const queryParams = URLQueryParams.parse(query);

                if (queryParams) {
                    this.cleanParams();
                }

                await Promise.all([
                    this.setReportVerticalTypeFromFlatten(queryParams.v),
                    this.setReportGroupsFromFlatten(queryParams.gs),
                    this.setReportFieldsFromFlatten(queryParams.fs),
                    this.setReportFiltersFromFlatten(queryParams.f),
                    this.setReportConstraintsFromFlatten(queryParams.c),
                    this.setReportTimeZoneFromFlatten(queryParams.tz),
                    this.setReportTimeFrameFromQueryString(queryParams),
                    this.setReportCompareFromQueryString(queryParams),
                    this.setReportCompareTimeFrameFromQueryString(queryParams)
                ]);

            } catch (e) {
                this.alertsManager.setBasicAlert({
                    content: `Failed to parse query params`,
                }, AlertAccent.Error);
            }
        }
    }

    private setReportTimeFrameFromQueryString(queryParams: any): boolean {
        let isSet = false;

        if (queryParams.t) {
            const validPresetValues = TimePresets.map((preset) => preset.value);

            if (validPresetValues.indexOf(queryParams.t) > -1) {
                isSet = true;

                this.setParam("time", queryParams.t);

                if (queryParams.t === "custom" && queryParams.from && queryParams.to) {
                    const from = dayjs(queryParams.from, "X").utc(false);
                    const to = dayjs(queryParams.to, "X").utc(false);

                    if (from.isValid() && to.isValid() && from < to) {
                        this.setParam("from", from);
                        this.setParam("to", to);
                    } else {
                        this.params.time = TimePreset.Now;
                    }
                } else {
                    this.applyTimePreset(queryParams.t);
                }
            }
        }

        return isSet;
    }

    private setReportCompareFromQueryString(queryParams: any): boolean {
        let isSet = false;

        if ("compare" in queryParams) {
            isSet = true;
            this.params.isCompare = queryParams.compare === "true";
        }

        return isSet;
    }

    private setReportCompareTimeFrameFromQueryString(queryParams: any): boolean {
        let isSet = false;

        if (queryParams.p) {
            if (VALID_PERIODS.includes(queryParams.p)) {
                isSet = true;

                this.setParam("period", queryParams.p);

                if (queryParams.p === "custom" && queryParams.cfrom && queryParams.cto) {
                    const from = dayjs(queryParams.cfrom, "X").utc(false);
                    const to = dayjs(queryParams.cto, "X").utc(false);

                    if (from.isValid() && to.isValid() && from < to) {
                        this.setParam("compareFrom", from);
                        this.setParam("compareTo", to);
                    } else {
                        this.params.time = VALID_PERIODS[0] as TimePreset;
                    }
                }
            }
        }

        return isSet;
    }

    public setSubGroupParam = (subGroup: ISubGroup, key: string, value: any) => {
        if (key === "group") {
            this.setSubGroup(subGroup, value);
            return;
        }
        subGroup[key] = value;
    };

    public setSubGroup = (subGroup: ISubGroup, group: string) => {
        subGroup.group = group;
        this.reportingSubGroupsManager.getSubGroupsEntities(group, this.params.verticalType);

        if (this.params.groups.findIndex((g) => g.value === RequestedGroupValue[group]) === -1) {
            this.pushReportParam("groups", RequestedGroupValue[group], RequestedGroupLabel[group]);
        }
    };

    public removeSubGroup = (subGroup: ISubGroup) => {
        this.params.subGroups = this.params.subGroups.filter((sub) => sub.id !== subGroup.id);
    };

    public pushSubGroupValue = (subGroup: ISubGroup, value: string, label: string) => {
        subGroup.entities = subGroup.entities.concat(value);
    };

    public removeSubGroupValue = (subGroup: ISubGroup, value: string) => {
        subGroup.entities = subGroup.entities.filter((item) => item !== value);
    };

    public pushSubGroupTargetingType = (subGroup: ISubGroup, value: string, label: string) => {
        subGroup.targeting.types = subGroup.targeting.types.concat(value);
    };

    public removeSubGroupTargetingType = (subGroup: ISubGroup, value: string) => {
        subGroup.targeting.types = subGroup.targeting.types.filter((item) => item !== value);
    };

    public pushSubGroupTargetingOperator = (subGroup: ISubGroup, value: string, label: string) => {
        subGroup.targeting.operators = subGroup.targeting.operators.concat(value);
    };

    public removeSubGroupTargetingOperator = (subGroup: ISubGroup, value: string) => {
        subGroup.targeting.operators = subGroup.targeting.operators.filter((item) => item !== value);
    };

    public setGroups(groups: IReportingEntry[]) {
        this.params.groups = groups;
    }

    public setFields(fields: IReportingEntry[]) {
        this.params.fields = fields;
    }
}
