import * as React from 'react';
import clsx from 'clsx';
import theme from './theme.module.scss';
import {IconButton, LinkButton, Loader, PageMessage, SearchBar} from '@vidazoo/ui-kit';
import {observer} from 'mobx-react';
import useUserNotificationsManager from "../../hooks/useUserNotificationsManager";
import {useNavigate} from "react-router-dom";
import UrlsService from "../../../../common/router/services/urlsService.mdl";
import NotificationMessage from "./components/notification-message/NotificationMessage";
import NotificationEntity from "./components/notification/Notification";
import globalTheme from '../../../../common/styles/_baseForm.module.scss';


function NotificationCenter(): JSX.Element {
    const manager = useUserNotificationsManager();
    const navigate = useNavigate();
    const {filteredItems, isLoading, onSelectItem, selectedItem, loadError, selectedItemIndex, search, items} = manager;

    const onSearch = (e) => {
        manager.setSearch(e.target.value);
    };

    const onSearchClear = () => {
        manager.clearSearch();
    };

    const onSelectForward = () => {
        if (selectedItemIndex + 1 < items.length) {
            return onSelectItem(items[selectedItemIndex + 1]._id);
        }
    };

    const onSelectPrevious = () => {
        if ((selectedItemIndex - 1 < items.length) && selectedItemIndex > 0) {
            return onSelectItem(items[selectedItemIndex - 1]._id);
        }
    };

    const onSettingsClick = () => {
        navigate(UrlsService.notificationSettings());
    };

    const renderLoader = (): JSX.Element => {
        return (
            <div className={theme.pageMessageContainer}>
                <div className={globalTheme.message}>
                    <Loader size={1} gray className={globalTheme.loader}/>
                    <PageMessage
                        small
                        title="Loading Notification Center"
                        description="Please wait..."
                    />
                </div>
            </div>
        );
    };

    const renderError = () => {
        return (
            <div className={theme.pageMessageContainer}>
                <div className={globalTheme.message}>
                    <PageMessage
                        small
                        title="Failed to load Notification Center"
                        description="We had an error loading the requested page."
                        iconName="icon-rejected-status"
                    />
                </div>
            </div>
        );
    };

    const renderEmpty = () => {
        return (
            <div className={theme.notificationEmpty}>
                <PageMessage
                    small
                    title="No notification selected"
                    description="Please select a notification to continue..."
                    iconName="icon-warning"
                />
            </div>
        );
    };

    return (
        <div className="w-full h-96 p-8">
            {!loadError ?
                <div className={theme.container}>
                    {!isLoading && filteredItems ?
                        <div style={{flexDirection: 'row', display: 'flex'}}>
                            <div className={theme.itemsContainer}>
                                <div className={theme.inner}>
                                    <div className={theme.settingsClass} onClick={onSettingsClick}>
                                        <LinkButton iconName="icon-settings" iconSize={2.2}/>
                                        <div className={theme.notificationSettings}>Notification Settings</div>
                                    </div>
                                    <div className={theme.searchContainer}>
                                        <SearchBar crossOrigin className={theme.searchBar} inner placeholder="Search"
                                                   value={search}
                                                   onChange={onSearch} onClear={onSearchClear}
                                                   onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                    </div>
                                    <div className={theme.notifications}>
                                        {filteredItems.map((notification) => (
                                            <NotificationEntity
                                                key={notification._id}
                                                notification={notification}
                                                onSelectItem={onSelectItem}
                                                selectedItemId={selectedItem ? selectedItem._id : null}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {selectedItem ?
                                <div className={theme.notificationMessageContainer}>
                                    <div className={theme.topBar}>
                                        <div className={theme.left} onClick={onSettingsClick}>
                                        </div>
                                        <div className={theme.right}>
                                            <div
                                                className={theme.count}>{selectedItemIndex + 1} / {filteredItems.length}</div>
                                            <div className={theme.controls}>
                                                <IconButton
                                                    className={clsx(theme.icon, {[theme.disabled]: selectedItemIndex === 0})}
                                                    iconName="icon-arrow-left-small" onClick={onSelectPrevious}/>
                                                <IconButton
                                                    className={clsx(theme.icon, {[theme.disabled]: selectedItemIndex === filteredItems.length - 1})}
                                                    iconName="icon-arrow-right-small" onClick={onSelectForward}/>
                                            </div>
                                        </div>
                                    </div>
                                    <NotificationMessage selectedItem={selectedItem}/>
                                </div>
                                : renderEmpty()}
                        </div>
                        : null}
                </div>
                : null}

            {isLoading ? renderLoader() : null}
            {loadError ? renderError() : null}
        </div>
    );
}

export default observer(NotificationCenter);
